import NavLinkButton from "@partners/@components/atoms/NavLinkButton";
import NavLinkCard from "@partners/@components/atoms/NavLinkCard";
import { BreakPoint, useScreenSize } from "hooks";
import { useTranslation } from "react-i18next";

function DashboardLinkCard({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { screen } = useScreenSize();
  const isSmallDevice = screen === BreakPoint.XS;

  return (
    <div className={className}>
      {isSmallDevice ? (
        <NavLinkButton to="/data" text={t(`home.keyMetrics.dashboardLink.link.mobile`)} />
      ) : (
        <NavLinkCard
          text={t(`home.keyMetrics.dashboardLink.text`)}
          link={t(`home.keyMetrics.dashboardLink.link.desktop`)}
          to="/data"
        />
      )}
    </div>
  );
}

export default DashboardLinkCard;
