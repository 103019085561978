import { ToggleLabelLoader } from "@partners/@components/atoms/Loaders";
import Toggle from "@partners/@components/atoms/Toggle";
import { ImpactType } from "@partners/@components/organisms/Impact";

export type ToggleProps = {
  impact: ImpactType;
  label: string;
  loading?: boolean;
  onClick: (impact: ImpactType) => void;
};

const ToggleWithLabel = ({ label, loading = false, ...rest }: ToggleProps) => (
  <div className="flex shrink-0 flex-col-reverse items-start sm:w-auto sm:flex-row sm:items-center">
    {loading ? (
      <ToggleLabelLoader />
    ) : (
      <div className="text-brand-800 mt-2 text-sm font-light sm:mr-3 sm:mt-0 sm:text-white ">{label}</div>
    )}
    <Toggle {...rest} loading={loading} />
  </div>
);

export default ToggleWithLabel;
