import MailIcon from "@partners/@assets/mail.svg?react";
import PhoneIcon from "@partners/@assets/phone.svg?react";
import { ContactType } from "@partners/@components/molecules/ContactCard";

const ContactLink = ({ text, href, icon }: { href: string; icon: ContactType; text: string }) => {
  return (
    <a
      className="inline-flex items-center justify-center rounded-2xl bg-white px-4 py-2 text-black"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {icon === "phone" ? <PhoneIcon /> : <MailIcon />}
      <span className="ml-2 text-sm font-semibold">{text}</span>
    </a>
  );
};

export default ContactLink;
