import { useAuth0 } from "@auth0/auth0-react";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import { ReactNode, useEffect } from "react";

import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";

const Auth0TrackingContainer = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  useEffect(() => {
    if (isLoading || isAuthenticated) return;

    trackPageView(PAGE_VIEWED.AUTH0_LOGIN_PAGE, mixpanelDynamicProps);
  }, [isAuthenticated, isLoading, mixpanelDynamicProps, trackPageView]);

  return children;
};

export default Auth0TrackingContainer;
