import { useAuth0 } from "@auth0/auth0-react";
import { HorizontalDivider } from "@partners/@components/atoms/Divider";
import NavItem from "@partners/@components/molecules/NavItem";
import classNames from "classnames";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  onMenuItemClicked: (path: string) => void;
}

function MobileMenu({ className, onMenuItemClicked }: Props) {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  return (
    <ul
      className={classNames(
        "pb-15 absolute bottom-0 left-0 right-0 top-16 flex h-screen w-full flex-col gap-6 bg-white px-8 pt-9 sm:px-14",
        className
      )}
    >
      <NavItem to={`/`} onClick={() => onMenuItemClicked("/")} underlineWhenActive={false}>
        {t("navBar.home")}
      </NavItem>
      <NavItem to={`data`} onClick={() => onMenuItemClicked("/data")} underlineWhenActive={false}>
        {t("navBar.data")}
      </NavItem>

      <HorizontalDivider color="light" size="medium" usePadding={false} />

      <button
        className="hover:text-brand-600 self-start font-normal text-neutral-600"
        onClick={handleLogout}
        name="logout"
        aria-label={t("navBar.logout") as string}
        title="logout"
      >
        {t("navBar.logout")}
      </button>
    </ul>
  );
}

export default MobileMenu;
