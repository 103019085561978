import { PARTNERS_TEST_IDS } from "constants/testIds";

import { HorizontalDivider } from "@partners/@components/atoms/Divider";
import Banner from "@partners/@components/molecules/Banner";
import Contact from "@partners/@components/organisms/Contact";
import Impact from "@partners/@components/organisms/Impact";
import KeyMetrics from "@partners/@components/organisms/KeyMetrics";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";

function Home() {
  const { t } = useTranslation();

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    if (!mixpanelDynamicProps.session_token) return;

    trackPageView(PAGE_VIEWED.HOME_PAGE, mixpanelDynamicProps);
  }, [mixpanelDynamicProps, trackPageView]);

  return (
    <div className="flex flex-col justify-center">
      <Banner title={t("home.title")} dataTestId={PARTNERS_TEST_IDS.HOMEPAGE}>
        <Impact />
      </Banner>
      <HorizontalDivider className="sm:hidden" />
      <KeyMetrics />
      <HorizontalDivider />
      <Contact />
    </div>
  );
}

export default Home;
