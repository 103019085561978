import { DrivenDistanceLabelLoader } from "@partners/@components/atoms/Loaders";
import classNames from "classnames";

// TODO add transition on distance change
const DrivenDistance = ({ className, distance, label }: { className?: string; distance: number; label?: string }) => (
  <div className={classNames("flex flex-col items-start gap-5 sm:items-end sm:gap-3.5", className)}>
    <div className="tracking-extra text-3xl font-semibold text-neutral-800 sm:-mr-3 sm:text-5xl sm:text-white">
      {distance.toLocaleString()}
    </div>
    {label ? (
      <span className="sm:text-partners-base text-partners-sm font-medium text-neutral-800 sm:text-right sm:text-white">
        {label}
      </span>
    ) : (
      <DrivenDistanceLabelLoader />
    )}
  </div>
);

export default DrivenDistance;
