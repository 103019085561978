import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../../graphql/types";
const defaultOptions = {} as const;
export type DataPageQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataPageQuery = {
  __typename?: "Query";
  company: { __typename?: "Company"; dashboardIframeUrl: string; id: string };
};

export const DataPageDocument = gql`
  query DataPage {
    company {
      id
      dashboardIframeUrl
    }
  }
`;

/**
 * __useDataPageQuery__
 *
 * To run a query within a React component, call `useDataPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataPageQuery(baseOptions?: Apollo.QueryHookOptions<DataPageQuery, DataPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataPageQuery, DataPageQueryVariables>(DataPageDocument, options);
}
export function useDataPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataPageQuery, DataPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataPageQuery, DataPageQueryVariables>(DataPageDocument, options);
}
export function useDataPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DataPageQuery, DataPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataPageQuery, DataPageQueryVariables>(DataPageDocument, options);
}
export type DataPageQueryHookResult = ReturnType<typeof useDataPageQuery>;
export type DataPageLazyQueryHookResult = ReturnType<typeof useDataPageLazyQuery>;
export type DataPageSuspenseQueryHookResult = ReturnType<typeof useDataPageSuspenseQuery>;
export type DataPageQueryResult = Apollo.QueryResult<DataPageQuery, DataPageQueryVariables>;
