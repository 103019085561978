export const AnalogyTextLoader = () => (
  <>
    <div className="bg-brand-600 animate-skeleton mb-2 h-[0.56rem] w-[3.375rem] rounded" />
    <div className="bg-brand-600 animate-skeleton h-[0.56rem] w-[4.31rem] rounded" />
  </>
);

export const AnalogyValueLoader = () => (
  <div className="bg-brand-600 animate-skeleton h-[1.69rem] w-[2.125rem] rounded" />
);

export const ToggleLabelLoader = () => <div className="animate-skeleton mr-3 h-4 w-[5.44rem] rounded bg-white" />;

export const DrivenDistanceLabelLoader = () => (
  <div className="animate-skeleton h-4 w-40 rounded bg-white lg:w-[22.125rem]" />
);

export const KeyMetricTitleLoader = () => <div className="animate-skeleton rounded-base h-5 w-[6.5rem] bg-green-500" />;
export const KeyMetricValueLoader = () => (
  <div className="animate-skeleton rounded-base mt-6 h-[2.31rem] w-[8.75rem] bg-green-500" />
);
export const KeyMetricVariationLoader = () => (
  <div className="animate-skeleton rounded-base mt-6 h-[1.625rem] w-[3.625rem] bg-green-500" />
);
