import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
  backgroundOnMobile?: boolean;
  children?: ReactNode;
  dataTestId?: string;
  title: string;
}

function Banner({ backgroundOnMobile = true, children, title, dataTestId }: Props) {
  return (
    <div
      className={classNames("sm:h-296 flex w-full items-center gap-2 sm:justify-between", {
        "bg-gradient-to-r px-4 sm:pl-14 sm:pr-0": !children,
        "from-banner-empty-from to-banner-empty-to py-6": !children && backgroundOnMobile,
        "justify-around ": backgroundOnMobile,
        "sm:from-banner-empty-from sm:to-banner-empty-to": !children && !backgroundOnMobile,
        "sm:from-brand-600 sm:to-banner-home-to bg-white sm:bg-gradient-to-br sm:px-14 sm:py-6": children,
      })}
    >
      <h1
        data-testid={dataTestId ?? "banner-title"}
        className={classNames("my-0 items-center font-light sm:w-2/3", {
          "hidden text-5xl leading-[4.375rem] text-white sm:flex": backgroundOnMobile,
          "text-partners-xl pt-8 leading-8 text-neutral-800 sm:pt-0 sm:text-5xl sm:leading-[4.375rem] sm:text-white":
            !backgroundOnMobile,
        })}
      >
        {title}
      </h1>
      {children ?? (
        <div
          className={classNames("h-296 w-46 bg-banner-image", {
            "hidden sm:flex": !backgroundOnMobile,
          })}
        />
      )}
    </div>
  );
}

export default Banner;
