import Error from "@partners/@components/organisms/Error";
import Layout from "@partners/@components/templates/Layout";
import Auth0ProviderWithNavigate from "@partners/@features/auth/Auth0ProviderWithNavigate";
import AuthenticationGuard from "@partners/@features/auth/AuthenticationGuard";
import Data from "@partners/@pages/Data";
import Home from "@partners/@pages/Home";
import { useMixpanel } from "@webapps/shared/hooks";
import { initMixpanel } from "@webapps/shared/libs";
import { version } from "@webapps/shared/utils";
import { Suspense, useEffect } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import ErrorPage from "./pages/ErrorPage";

function App() {
  const mixpanelTokenFromEnv = import.meta.env.VITE_MIXPANEL_TOKEN;
  const isDev = import.meta.env.DEV;
  const setMixpanel = useMixpanel((state) => state.setMixpanel);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Auth0ProviderWithNavigate />} errorElement={<ErrorPage />}>
        <Route path="/" element={<AuthenticationGuard component={Layout} />}>
          <Route index element={<Home />} errorElement={<Error />} />
          <Route path="data" element={<Data />} errorElement={<Error />} />
        </Route>
      </Route>
    )
  );

  useEffect(() => {
    const mixpanel = initMixpanel(mixpanelTokenFromEnv, "electra-partners", isDev);
    setMixpanel(mixpanel, { app_version: version, product_name: "webapp-partners" });
  }, [setMixpanel, isDev, mixpanelTokenFromEnv]);

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
