import { withAuthenticationRequired } from "@auth0/auth0-react";
import Invitation from "@partners/@pages/Invitation";
import { FunctionComponent } from "react";

const AuthenticationGuard = ({ component }: { component: FunctionComponent }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Invitation />,
  });

  return <Component />;
};

export default AuthenticationGuard;
