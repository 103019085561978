import ChevronRightIcon from "@partners/@assets/chevron-right.svg?react";
import classNames from "classnames";
import { NavLink as RouterNavLink } from "react-router-dom";

interface Props {
  className?: string;
  padding?: boolean;
  text: string;
  to: string;
}

const NavLink = ({ className, padding = true, text, to }: Props) => {
  return (
    <RouterNavLink
      className={classNames(
        "rounded-base inline-flex items-center justify-center gap-1 bg-green-100 text-neutral-600",
        className,
        {
          "px-5 py-1": padding,
        }
      )}
      to={to}
    >
      <span className="text-partners-sm font-semibold">{text}</span>
      <ChevronRightIcon />
    </RouterNavLink>
  );
};

export default NavLink;
