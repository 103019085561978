import ChartIcon from "@partners/@assets/chart.svg?react";
import ChevronRightIcon from "@partners/@assets/chevron-right.svg?react";
import { NavLink } from "react-router-dom";

const NavLinkButton = ({ text, to }: { text: string; to: string }) => {
  return (
    <NavLink className="bg-brand-600 flex w-full items-center justify-between rounded-2xl p-4 text-white" to={to}>
      <div className="flex items-center justify-between">
        <ChartIcon width="1.5rem" height="1.5rem" />
        <span className="text-partners-sm ml-3 font-normal">{text}</span>
      </div>
      <ChevronRightIcon className="flex-none" />
    </NavLink>
  );
};

export default NavLinkButton;
