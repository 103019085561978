import { PARTNERS_TEST_IDS } from "constants/testIds";

import ErrorMessage from "@partners/@components/atoms/ErrorMessage";
import Banner from "@partners/@components/molecules/Banner";
import { useMixpanel } from "@webapps/shared/hooks";
import { PAGE_VIEWED } from "@webapps/shared/libs";
import IframeResizer from "iframe-resizer-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useMixpanelDynamicProps from "../../hooks/useMixpanelDynamicProps";

import { useDataPageQuery } from "./graphql/query.generated";

function Data() {
  const { t } = useTranslation();
  // loading will be handled by skeletons
  const { data, error } = useDataPageQuery({ fetchPolicy: "network-only" });

  const trackPageView = useMixpanel((state) => state.trackPageView);
  const mixpanelDynamicProps = useMixpanelDynamicProps();

  //
  // Tracking plan
  //

  useEffect(() => {
    if (!mixpanelDynamicProps.session_token) return;

    trackPageView(PAGE_VIEWED.DASHBOARD_PAGE, mixpanelDynamicProps);
  }, [mixpanelDynamicProps, trackPageView]);

  return (
    <div className="flex flex-1 flex-col">
      <Banner title={t("data.title")} backgroundOnMobile={false} dataTestId={PARTNERS_TEST_IDS.DASHBOARD} />
      {!!error?.message && <ErrorMessage className="p-4 sm:px-14" message={error.message} />}
      {!!data?.company.dashboardIframeUrl && <IframeResizer src={data?.company.dashboardIframeUrl} />}
    </div>
  );
}

export default Data;
