import NavLink from "@partners/@components/atoms/NavLink";

const NavLinkCard = ({ text, link, to }: { link: string; text: string; to: string }) => (
  <div className="bg-brand-600 h-key-metric-card flex flex-col items-start justify-between rounded-3xl p-6">
    <p className="text-partners-base font-normal text-white">{text}</p>
    <NavLink padding={false} className="py-1.5 pl-4 pr-1.5" to={to} text={link} />
  </div>
);

export default NavLinkCard;
