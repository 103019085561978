import InformationIcon from "@partners/@assets/info.svg?react";
import {
  KeyMetricTitleLoader,
  KeyMetricValueLoader,
  KeyMetricVariationLoader,
} from "@partners/@components/atoms/Loaders";
import Pill from "@partners/@components/atoms/Pill";
import classNames from "classnames";
import { BreakPoint, useScreenSize } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type KeyMetric = "sessions" | "energy" | "availability" | "co2";

type Props = {
  className?: string;
  type: KeyMetric;
  value?: number;
  variation?: number;
};

function KeyMetricCard({ className, type, value, variation }: Props) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const { screen } = useScreenSize();
  const isSmallDevice = screen === BreakPoint.S;
  return (
    <div
      className={classNames(
        `bg-right bg-no-repeat ${
          showTooltip ? "bg-neutral-100" : "bg-green-50"
        } h-key-metric-card flex w-full flex-col rounded-3xl p-6`,
        className,
        {
          "bg-availability": type === "availability",
          "bg-co2": type === "co2",
          "bg-energy": type === "energy",
          "bg-sessions": type === "sessions",
          "gap-4": showTooltip,
          "justify-between": !showTooltip,
        }
      )}
      onClick={isSmallDevice && showTooltip ? () => setShowTooltip(false) : undefined}
    >
      {value === undefined && variation === undefined ? (
        <>
          <KeyMetricTitleLoader />
          <KeyMetricValueLoader />
          <KeyMetricVariationLoader />
        </>
      ) : (
        <>
          <div className="text-partners-base flex gap-1 font-semibold text-neutral-600">
            {t(`home.keyMetrics.${type}.title`)}
            <div
              className={showTooltip ? "cursor-default" : "cursor-pointer"}
              onMouseEnter={isSmallDevice ? undefined : () => setShowTooltip(true)}
              onMouseLeave={isSmallDevice ? undefined : () => setShowTooltip(false)}
              onClick={isSmallDevice ? () => setShowTooltip(true) : undefined}
            >
              <InformationIcon className={showTooltip ? "opacity-0" : ""} />
            </div>
          </div>

          <p className={showTooltip ? "text-brand-900 text-partners-base" : "hidden"}>
            {t(`home.keyMetrics.${type}.hint`)}
          </p>
          <div className={showTooltip ? "hidden" : "flex items-center"}>
            <span className="text-brand-900 text-5xl font-semibold">{value?.toLocaleString()}</span>
            <span className="text-brand-900 text-partners-2xl ml-1 font-medium">
              {t(`home.keyMetrics.${type}.label`)}
            </span>
          </div>
          <div className={showTooltip ? "hidden" : "flex items-center"}>
            <Pill value={variation!} />
            <span className="ml-1 text-sm font-medium text-neutral-600">{t(`home.keyMetrics.previousValue`)}</span>
          </div>
        </>
      )}
    </div>
  );
}

export default KeyMetricCard;
