import { PARTNERS_TEST_IDS } from "constants/testIds";

import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@partners/@assets/close.svg?react";
import logo from "@partners/@assets/logo.svg";
import LogoutIcon from "@partners/@assets/logout.svg?react";
import MenuIcon from "@partners/@assets/menu.svg?react";
import { VerticalDivider } from "@partners/@components/atoms/Divider";
import NavItem from "@partners/@components/molecules/NavItem";
import { useScreenSize, BreakPoint } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterNavLink } from "react-router-dom";

import MobileMenu from "../MobileMenu";

import { useNavBarQuery } from "./graphql/query.generated";

function NavBar() {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { data } = useNavBarQuery();

  const { screen } = useScreenSize();
  const isMediumDevice = screen === BreakPoint.M;

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  useEffect(() => {
    if (isMediumDevice) {
      setMobileMenuOpened(false);
    }
  }, [isMediumDevice]);

  return (
    <nav className="sticky top-0 z-50 flex items-center bg-white">
      {mobileMenuOpened && (
        <MobileMenu
          className="absolute bottom-0 left-0 right-0 top-16"
          onMenuItemClicked={() => setMobileMenuOpened(false)}
        />
      )}

      <div className="shadow-navbar z-50 flex h-16 w-full justify-between px-4 sm:px-14">
        <div className="flex items-center gap-6">
          <RouterNavLink to="/">
            <img src={logo} className="h-4 sm:h-6" alt="Electra logo" />
          </RouterNavLink>
          <VerticalDivider />
          {data?.company.name && !data?.company.logoUrl && (
            <div className="text-lg font-medium text-neutral-800 md:text-xl">{data?.company.name}</div>
          )}
          {data?.company.logoUrl && (
            <img src={data.company.logoUrl} className="h-4 sm:h-6" alt={data?.company.name ?? "Partner logo"} />
          )}
        </div>
        <div className="flex items-center gap-6">
          <ul className="hidden items-center gap-6 md:flex">
            <NavItem to={`/`}>{t("navBar.home")}</NavItem>
            <NavItem to={`data`} dataTestId={PARTNERS_TEST_IDS.DASHBOARD_LINK}>
              {t("navBar.data")}
            </NavItem>
          </ul>
          <VerticalDivider className="hidden md:flex" />

          <button
            className="hidden md:flex"
            onClick={handleLogout}
            name="logout"
            aria-label={t("navBar.logout") as string}
            title="logout"
          >
            <LogoutIcon className="hover:text-brand-600 h-6 w-6 cursor-pointer text-neutral-600" />
          </button>
          <button
            className="flex md:hidden"
            onClick={() => setMobileMenuOpened(!mobileMenuOpened)}
            name="toggle-menu"
            aria-label={t("navBar.menu") as string}
            title="toggle-menu"
          >
            {mobileMenuOpened ? (
              <CloseIcon className="hover:text-brand-600 h-6 w-6 cursor-pointer text-neutral-600" />
            ) : (
              <MenuIcon className="hover:text-brand-600 h-6 w-6 cursor-pointer text-neutral-600" />
            )}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
