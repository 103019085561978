import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../../../graphql/types";
const defaultOptions = {} as const;
export type KeyMetricQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KeyMetricQuery = {
  __typename?: "Query";
  company: {
    __typename?: "Company";
    energyDelivered: number;
    energyDeliveredVariation: number;
    id: string;
    savedCo2: number;
    savedCo2Variation: number;
    sessionsDelivered: number;
    sessionsDeliveredVariation: number;
  };
};

export const KeyMetricDocument = gql`
  query KeyMetric {
    company {
      id
      energyDelivered
      energyDeliveredVariation
      savedCo2Variation
      savedCo2
      sessionsDelivered
      sessionsDeliveredVariation
    }
  }
`;

/**
 * __useKeyMetricQuery__
 *
 * To run a query within a React component, call `useKeyMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyMetricQuery({
 *   variables: {
 *   },
 * });
 */
export function useKeyMetricQuery(baseOptions?: Apollo.QueryHookOptions<KeyMetricQuery, KeyMetricQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeyMetricQuery, KeyMetricQueryVariables>(KeyMetricDocument, options);
}
export function useKeyMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeyMetricQuery, KeyMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeyMetricQuery, KeyMetricQueryVariables>(KeyMetricDocument, options);
}
export function useKeyMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<KeyMetricQuery, KeyMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KeyMetricQuery, KeyMetricQueryVariables>(KeyMetricDocument, options);
}
export type KeyMetricQueryHookResult = ReturnType<typeof useKeyMetricQuery>;
export type KeyMetricLazyQueryHookResult = ReturnType<typeof useKeyMetricLazyQuery>;
export type KeyMetricSuspenseQueryHookResult = ReturnType<typeof useKeyMetricSuspenseQuery>;
export type KeyMetricQueryResult = Apollo.QueryResult<KeyMetricQuery, KeyMetricQueryVariables>;
