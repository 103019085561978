import "normalize.css";
import ErrorPage from "@partners/@pages/ErrorPage";
import initI18next from "intl";
import { initBugsnag } from "libs";
import React from "react";
import ReactDOM from "react-dom/client";
import "tailwind/common.css";
import { electraEnv } from "utils";

import App from "./App";

const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;

const ErrorBoundary = initBugsnag(bugsnagApiKey, electraEnv);

initI18next(["partners", "common"], import.meta.env.DEV);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    {ErrorBoundary ? (
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    )}
  </React.StrictMode>
);
