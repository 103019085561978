import { ATLANTIC_WIDTH, EARTH_CIRCONFERENCE, MOON_CIRCONFERENCE } from "@partners/@constants";
import sample from "lodash/sample";
import { formatNumber } from "utils";

export enum AnalogyType {
  Moon = 0,
  Earth = 1,
  Atlantic = 2,
}

export const getCardContent = (
  type: 0 | 1 | 2 | undefined,
  value: number | undefined
): { className: string; value: number } => {
  switch (type) {
    case AnalogyType.Moon: {
      if (!value) return { className: "", value: 0 };
      return {
        className: "bg-moon bg-no-repeat min-w-analogy-moon-earth h-12",
        value: getComparisonWithMoonCirconference(value),
      };
    }
    case AnalogyType.Earth: {
      if (!value) return { className: "", value: 0 };
      return {
        className: "bg-earth bg-no-repeat min-w-analogy-moon-earth h-12",
        value: getComparisonWithEarthCirconference(value),
      };
    }
    case AnalogyType.Atlantic: {
      if (!value) return { className: "", value: 0 };
      return {
        className: "bg-atlantic bg-no-repeat min-w-analogy-atlantic h-12",
        value: getComparisonWithAtlanticWidth(value),
      };
    }
    default:
      return { className: "min-w-analogy-moon-earth h-12", value: 0 };
  }
};

export const getComparisonWithMoonCirconference = (value: number): number => formatNumber(value / MOON_CIRCONFERENCE);

export const getComparisonWithEarthCirconference = (value: number): number => formatNumber(value / EARTH_CIRCONFERENCE);

export const getComparisonWithAtlanticWidth = (value: number): number => formatNumber(value / ATLANTIC_WIDTH);

export const getRandomAnalogyType = (value: number): AnalogyType => {
  let analogyTypes = [AnalogyType.Moon, AnalogyType.Earth, AnalogyType.Atlantic];

  const comparisonWithEarth = getComparisonWithEarthCirconference(value);
  const comparisonWithMoon = getComparisonWithMoonCirconference(value);

  if (comparisonWithEarth === 0 && comparisonWithMoon === 0) {
    return AnalogyType.Atlantic;
  } else if (comparisonWithEarth === 0) {
    analogyTypes = analogyTypes.filter((type) => type !== AnalogyType.Earth);
  }

  return sample(analogyTypes) ?? AnalogyType.Atlantic;
};
