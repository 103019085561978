import ErrorMessage from "@partners/@components/atoms/ErrorMessage";
import { useTranslation } from "react-i18next";

export default function ErrorInfo({ title, message }: { message?: string; title?: string }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center p-4">
      {title && <h1>{title}</h1>}
      <ErrorMessage message={message ?? t("api.unknownError", { ns: "common" })} />
    </div>
  );
}
