import { formatNumber } from "utils";

const Pill = ({ value }: { value: number }) => {
  const className = value > 0 ? "bg-green-200" : "bg-neutral-200";
  const sign = value > 0 ? "+" : "";

  return (
    <div className={`h-26 rounded-base inline-flex min-w-[40px] items-center justify-center p-1 ${className}`}>
      <span className="text-brand-900 text-partners-sm font-semibold">
        {sign}
        {value != null && formatNumber(value).toLocaleString()}%
      </span>
    </div>
  );
};

export default Pill;
