import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationResult } from "@auth0/auth0-spa-js";
import ErrorMessage from "@partners/@components/atoms/ErrorMessage";
import Footer from "@partners/@components/organisms/Footer";
import NavBar from "@partners/@components/organisms/NavBar";
import configureClient from "@partners/@graphql/client";
import Loading from "@partners/@pages/Loading";
import getAuthErrorMessage from "@partners/@utils/getAuthErrorMessage";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

function Layout() {
  const { t } = useTranslation();
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();

  const init = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const client = await configureClient(accessToken);
      setClient(client);
    } catch (accessTokenError) {
      if (
        (accessTokenError as AuthenticationResult).error === "missing_refresh_token" ||
        (accessTokenError as AuthenticationResult).error === "invalid_grant"
      ) {
        try {
          await loginWithRedirect();
        } catch (loginWithRedirectError) {
          const message = getAuthErrorMessage(loginWithRedirectError);
          setError(message ?? t("error.login"));
        }
      } else {
        const message = getAuthErrorMessage(accessTokenError);
        setError(message ?? t("error.login"));
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect, t]);

  useEffect(() => {
    if (isAuthenticated && !client) {
      init();
    }
  }, [client, isAuthenticated, init, navigate]);

  if (isLoading || !client) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={client}>
      <div className="flex min-h-screen flex-col justify-between bg-white">
        <NavBar />
        {error ? (
          <div className="flex justify-center">
            <ErrorMessage message={error} />
          </div>
        ) : (
          <Outlet />
        )}
        <Footer />
      </div>
    </ApolloProvider>
  );
}

export default Layout;
