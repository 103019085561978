import SectionTitle from "@partners/@components/atoms/SectionTitle";
import ContactCard from "@partners/@components/molecules/ContactCard";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { getCurrentLanguage } from "utils";

function Contact() {
  const { t } = useTranslation();
  const language = getCurrentLanguage();

  return (
    <div
      className={classNames(
        "lg:bg-hand flex w-full flex-col bg-no-repeat px-4 pb-14 pt-8 sm:p-14 lg:bg-right xl:bg-[length:498px_auto]",
        {
          "xl:bg-help-en": language !== "fr",
          "xl:bg-help-fr": language === "fr",
        }
      )}
      id="contact"
    >
      <SectionTitle>{t("home.contact.title")}</SectionTitle>
      <div className="grid grid-cols-4 gap-2 md:grid-cols-12 md:gap-6">
        <ContactCard type="phone" className="col-span-4 md:col-span-6 lg:col-span-4 xl:col-span-3" />
        <ContactCard type="mail" className="col-span-4 md:col-span-6 lg:col-span-4 xl:col-span-3" />
      </div>
      <p className="mt-5 text-sm font-normal text-neutral-800">
        <Trans
          i18nKey="home.contact.helpCenter.text"
          components={{
            1: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className="underline"
                href={t("home.contact.helpCenter.link") ?? undefined}
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      </p>
    </div>
  );
}

export default Contact;
