import ChevronRightIcon from "@partners/@assets/chevron-right.svg?react";
import MailIcon from "@partners/@assets/mail.svg?react";
import PhoneIcon from "@partners/@assets/phone.svg?react";
import { ContactType } from "@partners/@components/molecules/ContactCard";

const LinkButton = ({ text, href, icon }: { href: string; icon: ContactType; text: string }) => {
  return (
    <a
      className="rounded-base my-2 mb-1 flex items-center justify-between bg-neutral-100 p-4 text-black"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex items-center justify-between">
        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-full bg-white">
          {icon === "phone" ? <PhoneIcon /> : <MailIcon />}
        </div>
        <span className="text-partners-sm ml-3 font-semibold">{text}</span>
      </div>
      <ChevronRightIcon className="flex-none" />
    </a>
  );
};

export default LinkButton;
