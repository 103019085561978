import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className: string;
  onClick: () => void;
};

const ToggleButton = ({ children, className, onClick }: Props) => (
  <div
    role="button"
    className={`flex h-7 w-7 items-center justify-center rounded-full transition delay-150 ease-in-out ${className}`}
    onClick={onClick}
  >
    {children}
  </div>
);

export default ToggleButton;
