import ErrorMessage from "@partners/@components/atoms/ErrorMessage";
import SectionTitle from "@partners/@components/atoms/SectionTitle";
import DashboardLinkCard from "@partners/@components/molecules/DashboardLinkCard";
import KeyMetricCard from "@partners/@components/molecules/KeyMetricCard";
import { POLLING_INTERVAL_IN_MS } from "@partners/@constants";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";

import { useKeyMetricQuery } from "./graphql/query.generated";

function KeyMetrics() {
  const { t } = useTranslation();
  const { data, error } = useKeyMetricQuery({ pollInterval: POLLING_INTERVAL_IN_MS });
  const hasError = data === null || error;

  return (
    <div className="flex w-full flex-col px-4 pb-11 pt-8 sm:p-14 ">
      <SectionTitle>{t("home.keyMetrics.title")}</SectionTitle>
      {hasError ? (
        <ErrorMessage message={error?.message || t("error.title")} />
      ) : (
        <div className="grid grid-cols-4 gap-2 md:grid-cols-12 md:gap-4">
          <KeyMetricCard
            className="col-span-4 md:col-span-6 lg:col-span-4 xl:col-span-3"
            type="sessions"
            value={data?.company.sessionsDelivered}
            variation={data?.company.sessionsDeliveredVariation}
          />
          <KeyMetricCard
            className="col-span-4 md:col-span-6 lg:col-span-4 xl:col-span-3"
            type="co2"
            value={data?.company.savedCo2 ? formatNumber(data.company.savedCo2) : undefined}
            variation={data?.company.savedCo2Variation}
          />
          <KeyMetricCard
            className="col-span-4 md:col-span-6 lg:col-span-4 xl:col-span-3"
            type="energy"
            value={data?.company.energyDelivered}
            variation={data?.company.energyDeliveredVariation}
          />
          {/* <KeyMetricCard
            className="col-span-4 md:col-span-6 lg:col-span-4 xl:col-span-3"
            type="availability"
            value={99}
            variation={-0.7}
          /> */}
          <DashboardLinkCard className="col-span-4 pt-2 md:col-span-6 md:pt-0 lg:col-span-4 xl:col-span-3" />
        </div>
      )}
    </div>
  );
}

export default KeyMetrics;
