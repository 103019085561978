import loadingImage from "@partners/@assets/loading-animation-200-white.gif";

function Loading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <img src={loadingImage} width="100" height="100" alt="loading" />
    </div>
  );
}

export default Loading;
