import { GenericError } from "@auth0/auth0-spa-js";

const getAuthErrorMessage = (error: unknown) =>
  typeof error === "string"
    ? error
    : error instanceof GenericError && "error_description" in error
      ? error.error_description
      : error instanceof GenericError && "error" in error
        ? error.error
        : undefined;

export default getAuthErrorMessage;
