import AnalogyCard from "@partners/@components/molecules/AnalogyCard";
import DrivenDistance from "@partners/@components/molecules/DrivenDistance";
import ToggleWithLabel from "@partners/@components/molecules/ToggleWithLabel";
import { POLLING_INTERVAL_IN_MS } from "@partners/@constants";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getRandomAnalogyType } from "../../molecules/AnalogyCard/helpers";

import { useImpactQuery } from "./graphql/query.generated";

export type ImpactType = "global" | "individual";
export type AnalogyCardType = 0 | 1 | 2;

function Impact() {
  const { t } = useTranslation();
  const { data, loading } = useImpactQuery({ pollInterval: POLLING_INTERVAL_IN_MS });
  const [impact, setImpact] = useState<ImpactType>("individual");
  const [analogyType, setAnalogyType] = useState<AnalogyCardType>();
  const isGlobal = impact === "global";
  const impactLabel = t("home.impact.label", { company: isGlobal ? "Electra" : data?.company.name });

  const drivenDistance = useMemo(() => {
    if (!data) {
      return 0;
    }
    if (isGlobal) {
      return data.drivenDistance;
    }
    return data.company.drivenDistance;
  }, [data, isGlobal]);

  const drivenLabel = loading
    ? undefined
    : t("drivenDistance.label", { company: isGlobal ? "Electra" : data?.company.name });

  useEffect(() => {
    if (!data || analogyType || loading) {
      return;
    }

    setAnalogyType(getRandomAnalogyType(drivenDistance));
  }, [analogyType, data, loading, impact, drivenDistance]);

  return (
    <div className="flex flex-1 flex-row-reverse items-center justify-between gap-2 px-4 sm:h-[15.5rem] sm:flex-initial sm:flex-col sm:items-end sm:gap-0 sm:px-0">
      <ToggleWithLabel onClick={setImpact} impact={impact} loading={loading} label={impactLabel} />
      <DrivenDistance className="py-8 sm:py-0" distance={drivenDistance} label={drivenLabel} />
      <AnalogyCard distance={drivenDistance} type={analogyType} />
    </div>
  );
}

export default Impact;
