import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";
import { getCurrentLocale } from "utils";

import Auth0TrackingContainer from "./Auth0TrackingContainer";

function Auth0ProviderWithNavigate() {
  const navigate = useNavigate();

  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;
  const audience = import.meta.env.VITE_AUTH0_API_AUDIENCE;
  const locale = getCurrentLocale();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
        scope: "read:dashboard read:company",
        ui_locales: locale,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Auth0TrackingContainer>
        <Outlet />
      </Auth0TrackingContainer>
    </Auth0Provider>
  );
}

export default Auth0ProviderWithNavigate;
