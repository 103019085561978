import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getCurrentLanguage } from "utils";

const useMixpanelDynamicProps = () => {
  const language = getCurrentLanguage();
  const [sessionToken, setSessionToken] = useState<string | null | undefined>(null);
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const fetchSessionToken = async () => {
      const idToken = await getIdTokenClaims();
      setSessionToken(idToken?.__raw);
    };
    fetchSessionToken();
  }, [getIdTokenClaims]);

  return {
    language,
    session_token: sessionToken ?? null,
  };
};

export default useMixpanelDynamicProps;
