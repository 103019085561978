import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../../../graphql/types";
const defaultOptions = {} as const;
export type ImpactQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ImpactQuery = {
  __typename?: "Query";
  company: { __typename?: "Company"; drivenDistance: number; id: string; name: string };
  drivenDistance: number;
};

export const ImpactDocument = gql`
  query Impact {
    drivenDistance
    company {
      id
      name
      drivenDistance
    }
  }
`;

/**
 * __useImpactQuery__
 *
 * To run a query within a React component, call `useImpactQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactQuery({
 *   variables: {
 *   },
 * });
 */
export function useImpactQuery(baseOptions?: Apollo.QueryHookOptions<ImpactQuery, ImpactQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImpactQuery, ImpactQueryVariables>(ImpactDocument, options);
}
export function useImpactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImpactQuery, ImpactQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImpactQuery, ImpactQueryVariables>(ImpactDocument, options);
}
export function useImpactSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ImpactQuery, ImpactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ImpactQuery, ImpactQueryVariables>(ImpactDocument, options);
}
export type ImpactQueryHookResult = ReturnType<typeof useImpactQuery>;
export type ImpactLazyQueryHookResult = ReturnType<typeof useImpactLazyQuery>;
export type ImpactSuspenseQueryHookResult = ReturnType<typeof useImpactSuspenseQuery>;
export type ImpactQueryResult = Apollo.QueryResult<ImpactQuery, ImpactQueryVariables>;
