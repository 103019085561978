import { AnalogyTextLoader, AnalogyValueLoader } from "@partners/@components/atoms/Loaders";
import { useTranslation } from "react-i18next";

import { AnalogyType, getCardContent } from "./helpers";

export type Props = {
  distance?: number;
  type?: AnalogyType;
};

const AnalogyCard = ({ type, distance }: Props) => {
  const { t } = useTranslation();
  const { value, className } = getCardContent(type, distance);

  return (
    <div className={`bg-brand-800 rounded-medium hidden justify-end px-4 py-2 sm:flex sm:items-center ${className}`}>
      <div className="mr-2 flex flex-col items-end">
        {type !== undefined ? (
          <>
            <span className="inline-block text-right text-[0.56rem] font-normal leading-4 text-white opacity-70">
              {t("analogyCard.label")}
            </span>
            <span className="inline-block text-right text-[0.56rem] font-medium leading-4 text-white opacity-80">
              {t(`analogyCard.${type}`)}
            </span>
          </>
        ) : (
          <AnalogyTextLoader />
        )}
      </div>
      {type !== undefined ? (
        <div className="text-[2rem] font-normal text-white opacity-80">{value.toLocaleString()}</div>
      ) : (
        <AnalogyValueLoader />
      )}
    </div>
  );
};

export default AnalogyCard;
