import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Loading from "../Loading";

const Invitation = () => {
  const { loginWithRedirect } = useAuth0();
  const { invitation, organization } = useParams();

  useEffect(() => {
    const handleInvitation = async () => {
      if (invitation && organization) {
        await loginWithRedirect({
          authorizationParams: {
            invitation,
            organization,
          },
        });
      }
    };
    handleInvitation();
  }, [loginWithRedirect, invitation, organization]);

  return <Loading />;
};

export default Invitation;
