import classNames from "classnames";

interface VerticalDividerProps {
  className?: string;
  size?: "medium" | "small";
}

export const VerticalDivider = ({ className, size = "medium" }: VerticalDividerProps) => (
  <div
    className={classNames("w-px bg-neutral-300", className, {
      "h-7": size === "small",
      "h-9": size === "medium",
    })}
  />
);

interface HorizontalDividerProps {
  className?: string;
  color?: "light" | "medium";
  size?: "full" | "medium";
  usePadding?: boolean;
}

export const HorizontalDivider = ({
  className,
  color = "medium",
  size = "full",
  usePadding = true,
}: HorizontalDividerProps) => (
  <div
    className={classNames(className, {
      "px-4 sm:px-14": usePadding,
    })}
  >
    <div
      className={classNames("h-px", {
        "bg-neutral-100": color === "light",
        "bg-neutral-300": color === "medium",
        "w-248": size === "medium",
        "w-full": size === "full",
      })}
    />
  </div>
);
