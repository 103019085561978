import { PARTNERS_TOKEN_STORAGE_KEY } from "constants/storage";

import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { invariant } from "@apollo/client/utilities/globals";
import { notifyWarningOnBugsnag } from "@webapps/shared/libs";
import { getCurrentLocale } from "utils";

/*
 * Config
 */

let graphqlUri: string | undefined;
const graphqlLinkElement = document.querySelector<HTMLLinkElement>("link[rel=graphql]");
if (graphqlLinkElement) {
  graphqlUri = graphqlLinkElement.href;
}

if (process.env.NODE_ENV === "development") {
  // To work on tablets, phone on local network.
  graphqlUri = "http://localhost:3000/partners/graphql";
} else if (process.env.NODE_ENV === "test") {
  graphqlUri = "http://localhost:3000/partners/graphql";
} else if (!graphqlUri) {
  throw new Error('Missing <link rel="graphql" /> html element');
}

function configureClient(token?: string) {
  const httpLink = new HttpLink({
    headers: {
      "X-Electra-App-Name": "partners",
      "X-Electra-App-Platform": "web",
    },
    uri: graphqlUri,
  });

  const middleware = new ApolloLink((operation, forward) => {
    const locale = getCurrentLocale();

    const baseHeaders = { "X-Electra-Locale": locale };
    // fallback localstorage token for storybook
    const authToken = token || localStorage.getItem(PARTNERS_TOKEN_STORAGE_KEY);
    const middlewareHeaders = token ? { ...baseHeaders, Authorization: `Bearer ${authToken}` } : baseHeaders;

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...middlewareHeaders,
        ...headers,
      },
    }));

    return forward(operation);
  });

  const cache = new InMemoryCache({});

  const blockedLink = onError(({ networkError }) => {
    if (networkError && "response" in networkError) {
      if (networkError.response.status === 460) {
        // tslint:disable-next-line
        invariant.warn(`App is blocked`);
      }

      const unauthorized = networkError.response.status === 401;
      notifyWarningOnBugsnag(networkError, unauthorized ? "info" : "error");
    }
  });

  const parseErrorLink = onError(({ networkError }) => {
    if (networkError && networkError.name === "ServerParseError") {
      invariant.warn(`Server parse error`);
      networkError.message = "Server parse error";
      notifyWarningOnBugsnag(networkError);
    }
  });

  const client = new ApolloClient({
    cache,
    link: from([blockedLink, parseErrorLink, middleware, httpLink]),
  });

  return client;
}

export default configureClient;
