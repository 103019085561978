import LeafIcon from "@partners/@assets/leaf.svg?react";
import TreesIcon from "@partners/@assets/trees.svg?react";
import { ToggleProps } from "@partners/@components/molecules/ToggleWithLabel";

import ToggleButton from "./ToggleButton";

const Toggle = ({ impact, onClick, loading }: Omit<ToggleProps, "label">) => {
  const isIndividual = impact === "individual";
  const leafClassName = loading || isIndividual ? "text-brand-50" : "text-brand-800 opacity-20";
  const treesClassName = loading ? "text-neutral-300" : isIndividual ? "text-brand-800 opacity-20" : "text-brand-800";
  const treesContainerClassName = loading ? "bg-transparent" : isIndividual ? "bg-transparent" : "bg-brand-300";
  const leafContainerClassName = loading ? "bg-neutral-300" : isIndividual ? "bg-brand-800" : "bg-transparent";
  return (
    <div className="w-74 relative inline-block h-9">
      <div className="bg-brand-50 absolute inset-0 cursor-pointer rounded-full shadow-lg">
        <div className="flex flex-row items-center justify-around p-1">
          <ToggleButton onClick={() => onClick("individual")} className={leafContainerClassName}>
            <LeafIcon className={`w-5 transition delay-150 ease-in-out ${leafClassName}`} />
          </ToggleButton>
          <ToggleButton onClick={() => onClick("global")} className={treesContainerClassName}>
            <TreesIcon className={`w-18 transition delay-150 ease-in-out ${treesClassName}`} />
          </ToggleButton>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
