import logo from "@partners/@assets/logo.svg";
import Error from "@partners/@components/organisms/Error";
import Footer from "@partners/@components/organisms/Footer";

function ErrorPage({ error }: { error?: Error }) {
  return (
    <div className="flex min-h-screen flex-col justify-between bg-white">
      <div className="px-4 py-8">
        <img src={logo} className="h-4 sm:h-6" alt="Electra logo" />
        <Error error={error} />
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;
