import LinkButton from "@partners/@components/atoms/LinkButton";
import LinkCard from "@partners/@components/atoms/LinkCard";
import { BreakPoint, useScreenSize } from "hooks";
import { useTranslation } from "react-i18next";

export type ContactType = "phone" | "mail";

function ContactCard({ className, type }: { className?: string; type: ContactType }) {
  const { t } = useTranslation();
  const { screen } = useScreenSize();
  const isSmallDevice = screen === BreakPoint.XS;

  return (
    <div className={className}>
      {isSmallDevice ? (
        <LinkButton
          icon={type}
          text={t(`home.contact.${type}.link.mobile`)}
          href={t(`home.contact.${type}.link.href`)}
        />
      ) : (
        <LinkCard
          title={t(`home.contact.${type}.title`)}
          text={t(`home.contact.${type}.text`)}
          href={t(`home.contact.${type}.link.href`)}
          icon={type}
          linkText={t(`home.contact.${type}.link.desktop`)}
        />
      )}
    </div>
  );
}

export default ContactCard;
