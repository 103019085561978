import CardTitle from "@partners/@components/atoms/CardTitle";
import { ContactType } from "@partners/@components/molecules/ContactCard";

import ContactLink from "../ContactLink";

type Props = {
  href: string;
  icon: ContactType;
  linkText: string;
  text: string;
  title: string;
};

const LinkCard = ({ title, text, href, icon, linkText }: Props) => (
  <div className="flex h-[13rem] flex-col items-center justify-between rounded-3xl bg-neutral-50 px-8 pb-10 pt-8">
    <CardTitle>{title}</CardTitle>
    <p className="text-center text-sm font-normal text-neutral-600">{text}</p>
    <ContactLink href={href} text={linkText} icon={icon} />
  </div>
);

export default LinkCard;
