import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../../../graphql/types";
const defaultOptions = {} as const;
export type NavBarQueryVariables = Types.Exact<{ [key: string]: never }>;

export type NavBarQuery = {
  __typename?: "Query";
  company: { __typename?: "Company"; id: string; logoUrl?: string | null; name: string };
};

export const NavBarDocument = gql`
  query NavBar {
    company {
      id
      logoUrl
      name
    }
  }
`;

/**
 * __useNavBarQuery__
 *
 * To run a query within a React component, call `useNavBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavBarQuery(baseOptions?: Apollo.QueryHookOptions<NavBarQuery, NavBarQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavBarQuery, NavBarQueryVariables>(NavBarDocument, options);
}
export function useNavBarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavBarQuery, NavBarQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavBarQuery, NavBarQueryVariables>(NavBarDocument, options);
}
export function useNavBarSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NavBarQuery, NavBarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavBarQuery, NavBarQueryVariables>(NavBarDocument, options);
}
export type NavBarQueryHookResult = ReturnType<typeof useNavBarQuery>;
export type NavBarLazyQueryHookResult = ReturnType<typeof useNavBarLazyQuery>;
export type NavBarSuspenseQueryHookResult = ReturnType<typeof useNavBarSuspenseQuery>;
export type NavBarQueryResult = Apollo.QueryResult<NavBarQuery, NavBarQueryVariables>;
